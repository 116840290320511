import Head from 'next/head';
import { QueryClient } from 'react-query';
import { dehydrate } from 'react-query/hydration';
import { BaseLink, H1, H2, MainBody } from 'shared/components';
import { MaxWidthContainer, Spacer } from 'shared/layout';
import { withTheme } from 'styled-components';
import { getError, getMetadataByUrl } from '../api';
import { LayoutContainer } from '../layout';
import React from 'react';

const ErrorPage = ({
	Heading = 'Ett fel har inträffat!',
	Preamble = '',
	Link = {
		Url: '',
		Name: '',
	},
	LinkButtonText = '',
	SiteTitle = '404',
	theme = {},
}) => {
	return (
		<LayoutContainer noHeader={false} SiteTitle={SiteTitle}>
			<Head>
				<title>{SiteTitle}</title>
			</Head>
			<MaxWidthContainer narrow>
				<Spacer spacing={8}>
					<H1>{Heading}</H1>
				</Spacer>
				<Spacer spacing={8}>
					<MainBody color={theme.colors.primary}>{Preamble}</MainBody>
				</Spacer>
				<Spacer spacing={8}>
					<BaseLink href={Link?.Url}>
						<H2>{LinkButtonText ?? Link?.Name}</H2>
					</BaseLink>
				</Spacer>
			</MaxWidthContainer>
		</LayoutContainer>
	);
};

export default withTheme(ErrorPage);

/* 
Since errorpage can be altered in backend we still need to fetch the data and it has to be done in this
component to allow next to properly send a 404-response.
*/
export async function getStaticProps() {
	const queryClient = new QueryClient();

	var metaData = await getMetadataByUrl('NotFoundPage');
	const page = await getError();
	return {
		props: {
			dehydratedState: dehydrate(queryClient),
			metaData,
			...page,
		},
		revalidate: 10,
	};
}
